import { render, staticRenderFns } from "./sensibilitySetting.vue?vue&type=template&id=43c0ceea&scoped=true"
import script from "./sensibilitySetting.vue?vue&type=script&lang=js"
export * from "./sensibilitySetting.vue?vue&type=script&lang=js"
import style0 from "./sensibilitySetting.vue?vue&type=style&index=0&id=43c0ceea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c0ceea",
  null
  
)

export default component.exports